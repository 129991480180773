<style>
  .qlist {
    display: none !important;
  }
</style>

<div class="row mobilebottomm">
  <div class="col-md-12">
    <app-questionpageheader *ngIf="question.examQuestionSchedule.scheduleid" [position]="'top'"
                            [qno]="question.examQuestionSchedule.questionno"
                            [islast]="question.questionCount==question.examQuestionSchedule.questionno"
                            [scheduleid]="question.examQuestionSchedule.scheduleid" [url]="url" (nextclicked)="gotoquestion($event)"
                            (saveclicked)="savequestion($event)" (previousclicked)="gotoquestion($event)"
                            [schedulequestionid]="question.examQuestionSchedule.schedulequestionid" [essayexam]="essayexam"
                            (timeelapsed)="timeelapsed($event)" [adminpaused]="adminpaused" [proctorpaused]="proctorpaused"
                            [flaggedquestion]="flagged" [examno]="question.examQuestionSchedule.examno" (flagclicked)="flagclicked($event)" [status]="status" [timeleft]="timeleft"
                            [useProctor]="question.examination.useproctor" [liteMode]="question.examination.liteMode">
    </app-questionpageheader>
  </div>
</div>
<div class="" *ngIf="!adminpaused && !proctorpaused">
  <section id="" class="about mobiletop" style="width: 100%;">
    <!-- <div *ngIf="isloadingdata" style="position:sticky;" class="alert center"
        style="padding: 0.75em 0; text-align: center;">
        <p class="font-weight-bold" style="color:white; background-color: cornflowerblue;">
            <span><img style="height:40px;" src="../../../assets/img/spinner.gif" /></span>
    </div> -->
    <div class="overlay" [ngClass]="{'show': isloadingdata}">
<!--<div *ngIf="markingnotworking" style="align-self: center;text-align: center;vertical-align: middle;">

</div>-->
    </div>
    <div class="spanner" [ngClass]="{'show': isloadingdata}">
      <div class="spin-wrap">
        <div class="spin-wrap-content">
          <div class="spinner-container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mx-auto border border-0 rounded-lg shadow-lg">
      <div class="row pb-0 paddedrow">

        <div class="col-lg-12 d-flex flex-column">
          <div *ngIf="question.examQuestion.instructions" style="border: 1px #ccc solid; padding:2px">
            <p><strong> {{language.instructions}}:</strong> {{question.examQuestion.instructions}}</p>
          </div>

          <div class="section-title pt-2 mt-2">
            <h2 style="font-family: Poppins !important;">
               {{language.question}} ({{(question.examQuestion.questionnolabel ? question.examQuestion.questionnolabel : question.examQuestionSchedule.questionno)}})
              {{question.examQuestion.category ? '(' + question.examQuestion.category + ')' : ''}} :
            </h2>
          </div>



          <div class="icon-box justify-content-center mx-3 pl-2 mb-2 h3" data-aos="fade-up" data-aos-delay="100">
            <section #questionhtmlsection class="lead main pt-0"
                     style="font-size: 20px !important; color: #000; font-family: Poppins !important;"></section>
          </div>
        </div>
      </div>
      <div class="row pb-0 paddedrow questionborder">
      </div>
      <div class="row pb-0 paddedrow" style="width: 100%;">

        <div class="col-lg-12 d-flex flex-column" *ngIf="!essayexam">

          <div class="section-title">
            <!-- <h2>Options:</h2> -->
            <!-- <h6>Select Option(s).</h6> -->
          </div>

          <div class="icon-box justify-content-center h3" data-aos="fade-up" data-aos-delay="100">
            <app-questionoptions style="font-family: Poppins !important;" *ngIf="this.question.examQuestionOptions"
                                 [options]="this.question.examQuestionOptions"
                                 [schedulequestionid]="question.examQuestionSchedule.schedulequestionid"
                                 [multiple]="question.hasMultipleAnswers" (radioselectedvalue)="OnRadioSelected($event)"
                                 (checkselectedvalue)="oncheckchanged($event)"></app-questionoptions>
          </div>
        </div>

        <app-essay class="col-lg-12 d-flex flex-column" *ngIf="essayexam" (updatedanswer)="updatedanswer($event)"
                     [question]="this.question"></app-essay>
      </div>
      <!-- <div>
            <app-questionpagefooter></app-questionpagefooter>
      </div> -->
    </div>
  </section>
</div>
<div class="row" *ngIf="!adminpaused && !proctorpaused">
  <div class="col-md-12">
    <app-questionpageheader *ngIf="question.questionCount" [position]="'bottom'"
                            [qno]="question.examQuestionSchedule.questionno"
                            [islast]="question.questionCount==question.examQuestionSchedule.questionno"
                            [scheduleid]="question.examQuestionSchedule.scheduleid" [url]="url" (nextclicked)="gotoquestion($event)"
                            [schedulequestionid]="question.examQuestionSchedule.schedulequestionid" (saveclicked)="savequestion($event)"
                            (previousclicked)="gotoquestion($event)" [essayexam]="essayexam" [adminpaused]="adminpaused"
                            [proctorpaused]="proctorpaused" [flaggedquestion]="flagged" [examno]="question.examQuestionSchedule.examno"
                            (flagclicked)="flagclicked($event)" [status]="status" [timeleft]="timeleft"
                            [useProctor]="question.examination.useproctor" [liteMode]="question.examination.liteMode">
    </app-questionpageheader>
  </div>
</div>
