import { Component, EventEmitter, Host, Input, Output, SimpleChanges, ViewChild } from '@angular/core'
import { EditorChangeContent, EditorChangeSelection, QuillEditorComponent } from 'ngx-quill'
import { style } from '@angular/animations'
import { QuestionserviceService } from '../services/questionservice.service';
import { ExamService } from '../services/exam.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from '../services/cbtservice.service';
import { LanguageService } from '../services/language.service';
import { QuestionComponent } from '../question/question.component';

declare const $: any;

@Component({
  selector: 'app-essay',
  templateUrl: './essay.component.html',
  styles: ['./essay.component.css']
})
export class EssayComponent {
//[essayanswer]="question.essayAnswer" [scheduleid]="question.examQuestionSchedule.scheduleid" [schedulequestionid]="question.examQuestionSchedule.schedulequestionid" [maxwordcount]="question.maxwordcount"

  // @Input() essayanswer: any = "<p><b>Something </b>fishy is going on</p>"
  @Input() question: any;
  essayanswer: any
  blurred = false
  focused = false;
  @ViewChild('answer') editor: QuillEditorComponent;
  quill: any = {};
  i = 0;
  previousQuestionHtml: any;
  maxwordcount: number = 0;
  scheduleid: any;
  schedulequestionid: any;
  thisurl = location.origin + '/';
  @Output() updatedanswer: EventEmitter<string> = new EventEmitter();
  currentwordcount: number = 0;
  wordcountdisplay: string;
  higlight: boolean;
  previousQuestion: any;
  constructor(public questionservice: QuestionserviceService, public examservice: ExamService, public toastr: ToastrService, public router: Router, private cbtservice: CbtserviceService, private languageService: LanguageService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //here, we need to know when we are switching from one question to another so that we save the data before displaying the next question
    //save the previous html before we set a new answer
    if(this.question?.examQuestionSchedule?.schedulequestionid != this.previousQuestion?.examQuestionSchedule?.schedulequestionid){
      //debugger;
      //if the question has changed, save the html in the editor to the html of the previous question
      if(this.previousQuestion){
        //var data = this.constructAnswerSaveData(this.quill.root.innerHTML);
        await this.saveData(null);
        this.previousQuestion.essayAnswer = this.quill.root.innerHTML;
        //save that question to storage
      }
      if(this.question){
        this.previousQuestion = this.question;
        this.essayanswer = this.question.essayAnswer;
        this.scheduleid = this.question.examQuestionSchedule.scheduleid;
        this.schedulequestionid = this.question.examQuestionSchedule.schedulequestionid;
        this.maxwordcount = this.question.maxwordcount;
      }
      this.setAnswer();
    }
    
  }

  get language() {
    return this.languageService.language;
  }

  // created(event: Quill) {
  //   // tslint:disable-next-line:no-console
  //   console.log('editor-created', event)
  // }


  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    // tslint:disable-next-line:no-console
    console.log('editor-change', event)
  }

  // focus($event) {
  //   // tslint:disable-next-line:no-console
  //   console.log('focus', $event)
  //   this.focused = true
  //   this.blurred = false
  // }

  // blur($event) {
  //   // tslint:disable-next-line:no-console
  //   console.log('blur', $event)
  //   this.focused = false
  //   this.blurred = true
  // }

  async ngOnInit() {

    // this.quill.root.innerHTML = this.essayanswer;
    //debugger;
    // this.quill?.quillEditor.root.setAttribute('spellcheck', 'false');
    // setTimeout(() => { this.setAnswer(); });//2s seconds

  }

  calculate() {
    const text = this.quill.getText().trim()
    let words = text.split(/\s+/);
    let wordlength = words.length;
    let allowedwords = this.maxwordcount > 0 ? text.split(/\s+/, this.maxwordcount) : words;
    let allowedxters = this.maxwordcount > 0 ? allowedwords.join().length : text.length;
    return !text ? 0 : [wordlength, allowedxters, text.length]

  }

  ngAfterViewInit(): void {
    const mythis = this;

    this.editor.onEditorCreated.subscribe(() => {
      this.quill = this.editor.quillEditor;
      this.editor.quillEditor.root.setAttribute('spellcheck', 'false');

      this.setAnswer();
      this.callSaveData()

    });
    this.editor.onEditorChanged.subscribe(() => {
      this.quill = this.editor.quillEditor;
      const length = this.calculate();
      this.currentwordcount = length[0];
      this.higlight = this.currentwordcount > this.maxwordcount && this.maxwordcount > 0;
      this.wordcountdisplay = !length[0] ? '' : length[0] + ' ' + 'word' + (length[0] > 1 ? 's' : '');
      this.quill.formatText(0, length[1], {
        'color': 'rgb(0,0,0)'
      });
      if (length[0] > this.maxwordcount && this.maxwordcount && this.maxwordcount > 0) {
        this.quill.formatText(length[1], length[2], {
          'color': 'rgb(220, 20, 60)'
        });
      }
    });
  }
  async saveData(callback) {
    //never use this.question here
    //this.question may have been updated earlier than the other relevant info
    //those are manually changed in onChanges()
    let questionHtml = this.quill.root.innerHTML;
    var data = this.constructAnswerSaveData(questionHtml);
    //debugger;
    if (this.previousQuestionHtml !== questionHtml) {
      //we won't use captcha for theory exams
      //this.questionservice.saveQuestionOption(data);
      await this.questionservice.markanswer(data);
      this.updatedanswer.emit(data.essayanswer)
      console.log(`saved data ${this.i}`);
      this.previousQuestionHtml = questionHtml;
      if (callback) callback();
    }
    else {
      console.log(`Not saving ${this.i} because questionHtml has not changed`);
      if (callback) callback();
    }
  }

  private constructAnswerSaveData(questionHtml: any) {
    return {
      "schedulequestionid": this.schedulequestionid,
      "scheduleid": this.scheduleid,
      "options": [],
      "essayanswer": questionHtml,
      "essay": true
    };
  }

  callSaveData() {
    this.i++;
    console.log(`saving data ${this.i}`);
    this.saveData(() => {
      setTimeout(() => { this.callSaveData(); }, 1000 * 2);//2s seconds
    });
  }
  savebtnclicked() {
    this.saveData(null);
  }

  


  setAnswer() {
    //debugger;
    // let a=  this.editor.quillEditor;
    // let ele = document.getElementsByClassName('ql-editor');
    //debugger;
    if (this.quill.root) {
      if (this.essayanswer) {
        this.quill.root.innerHTML = this.essayanswer;
      }
      else {
        this.quill.root.innerHTML = '';
      }
    }
  }

}


