import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CbtserviceService } from './cbtservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { Util } from '../interceptors/util';
import { ProctoringService } from './proctoring.service';
import { LanguageService } from './language.service';
//import { throwError } from 'rxjs';

declare var grecaptcha: any;
//declare var captchaSiteId: any;
enum EncryptMode {
  e = "encrypt",
  d = "decrypt"
}
@Injectable({
  providedIn: 'root'
})
export class QuestionserviceService {
  captchaSiteId: string;
  previousquestiondata: any
  nextquestiondata: any
  readonly rootURL = environment.CBTAPIURL;
  req_headers: HttpHeaders;
  public markingnotworking: boolean;
  //markingTimeout: any;
  markingQueue: any[] = [];
  private captchaReadyPromise: Promise<void>;
  public proctoringService: ProctoringService;
  useProctor: boolean = false;
  useCaptcha: boolean = false;
  liteMode: boolean = false;

  readonly captchaFailureRetryLimit = 2;
  captchaFailureCount = 0;

  constructor(private http: HttpClient, private cbtservice: CbtserviceService, private toastr: ToastrService, private router: Router, public encryptdecryptservice: EncryptDecryptService, private utilService: Util, private languageService: LanguageService) {
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json", "skip_error_handler_interceptor": "true" });//all the services here should skip the interceptor
    setTimeout(() => this.markingTimeoutLoopFunction());
  }
  get language() {
    return this.languageService.language;
  }
  init(useProctor: boolean, useCaptcha: boolean, liteMode: boolean){
    this.useProctor = useProctor;
    this.useCaptcha = useCaptcha;
    this.liteMode = liteMode;
    this.clearSubmissionQueue();
    if(!liteMode){
      localStorage.clear();
    }
    this.captchaFailureCount = 0;
  }

  clearSubmissionQueue(){
    this.markingQueue.splice(0);
  }

  async generatequestions(scheduleid: any): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + `question/generatequestions?scheduleid=${scheduleid}`, {}, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.generatequestions(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    return await this.cbtservice.tryPost(this.rootURL + `question/generatequestions?scheduleid=${scheduleid}`, {}, { headers: this.req_headers });
  }

  async startexam(scheduleid: any, useCaptcha: boolean): Promise<any> {
    // let attempts = 0;
    // while(true){
    //   attempts++;
    //   try{
    //     return await this.http.post(this.rootURL + `question/startexam?scheduleid=${scheduleid}`, {}, {headers: this.req_headers}).toPromise();
    //   }
    //   catch(error){
    //     if(attempts < environment.maxRequestRetries && (error.status === 0 || (error.error && error.error.message && error.error.message.toUpperCase().includes(environment.seamlessScalingError)))){
    //       //return await this.startexam(scheduleid);
    //       //probably gateway timeout (when status === 0) or seamless scaling error. retry
    //     }
    //     else{
    //       //this.cbtservice.showHttpError(error);
    //       //this.isloading = false;
    //       throw error;
    //     }
    //   }
    // }
    //let captchaToken;
    let startExamData: any = {scheduleid};
    if(useCaptcha){
      await this.loadCaptchaAndWaitForReadiness();
      startExamData.captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'startexam'});
    }
    
    //setting max attempts to 1 because we don't want to resend captchas
    return await this.cbtservice.tryPost(this.rootURL + `question/startexam`, startExamData, { headers: this.req_headers }, 1);
  }

  async getquestionandoptions(questionno: Number, liteMode: boolean, useCaptcha: boolean, scheduleid: any): Promise<any> {
    return await this.getQuestionNoFromStorage(questionno, scheduleid, liteMode, useCaptcha);
  }

  loadCaptchaAndWaitForReadiness(){
    //debugger;
    if(!this.captchaReadyPromise){
      this.captchaReadyPromise = new Promise<void>(async (resolve, reject) => {

        this.captchaSiteId = '6LdJ1DIqAAAAACkgKO0BS7TQVU8Nt9xbex4nhtGc';
        const captchaScriptTag = document.createElement('script');
        captchaScriptTag.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${this.captchaSiteId}`);
        document.getElementsByTagName('head')[0].appendChild(captchaScriptTag);
        while(typeof grecaptcha === 'undefined'){
          await this.utilService.sleep(200);
        }
        grecaptcha.enterprise.ready(async () => {
          //got text from here: https://developers.google.com/recaptcha/docs/faq
          //document.getElementById('recaptcha-footer').innerHTML = '<span>This site is protected by reCAPTCHA, and Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply</span>';
          //const token = await grecaptcha.enterprise.execute('6LdJ1DIqAAAAACkgKO0BS7TQVU8Nt9xbex4nhtGc', {action: 'LOGIN'});
          resolve();
        });
      });
    }
    return this.captchaReadyPromise;
  }

  async markanswer(data: any) {
    if(!data.schedulequestionid){
      debugger;
    }
    if (this.hasQuestionDataInStorage()) {
      data.saved = false;
      this.saveQuestionOption(data);
    }
    if (!this.liteMode) {
      this.markanswerasync(data);
    }
  }

  async getAnswerSubmissionPromise(data) {
    const promise = new Promise<any>((resolve, reject) => {
      this.markingQueue.push({ data, resolve });
    });
    return promise;
  }

  async waitForAllQuestionsToBeSubmitted() {
    while(this.markingQueue.length > 0){
      this.utilService.sleep(500);
    }
  }

  async markingTimeoutLoopFunction(){
    try{
      while(this.markingQueue.length > 0) {
        await this.cbtservice.runFunctionWithTimeout(async () => {
          const markingEntry = this.markingQueue[0];
          const data = markingEntry.data;

          if(this.useCaptcha){
            await this.loadCaptchaAndWaitForReadiness();
            const captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'answer'});
            data.captchaToken = captchaToken;
          }

          const promiseResolve = markingEntry.resolve;
          let lastVideoUploadedDetails = {};
          if(this.useProctor) {
            lastVideoUploadedDetails = await this.proctoringService.getLastVideoChunkUploadedDetails();
          }
          //await this.proctoringService.exam
          let encryptedata = JSON.stringify(this.encryptdecryptservice.encryptUsingAES256(JSON.stringify({...data, ...lastVideoUploadedDetails})));
          //setting max attempts to 1 because we don't want to resend captchas
          const ret = await this.cbtservice.tryPost(this.rootURL + 'question/saveanswer', encryptedata, { headers: this.req_headers }, 1);
          
          if(ret.captchaFailure){
            //don't proceed and try again up to max retries
            this.captchaFailureCount++;
            if(this.captchaFailureCount < this.captchaFailureRetryLimit){
              await this.utilService.sleep(1000);
              //returning here will keep this answer in the queue for the next pass
              return;
            }
            else{
              this.toastr.error(this.language.verificationError);
              //ret.logout = true;
              this.leaveExam();
            }
          }
          else{
            this.captchaFailureCount = 0;
          }

          if(ret.recordingVerificationFailure){
            this.leaveExam();
          }

          if(ret.saved){
            data.saved = true;
            this.saveQuestionOption(data);
            this.markingQueue.splice(0, 1);
          }
          promiseResolve(ret);
          this.markingnotworking = false;
        }, 30 * 1000);//to make sure a submission attempt never takes more than 30 seconds
        //this way, it can fail, and the user will know there is something wrong
      }
    }
    catch (error) {
      console.error(error);
      this.markingnotworking = true;
    }
    finally {
      setTimeout(() => {
        this.markingTimeoutLoopFunction();
      }, 100);
    }
  }

  leaveExam() {
    if (this.useProctor) {
      this.proctoringService.stopProctor('captcha failure');
    }
    this.clearSubmissionQueue();
    this.captchaFailureCount = 0;
    this.router.navigate(['/exam/userexams']);
  }

  async markanswerasync(data) {
    try {
      var saved = await this.getAnswerSubmissionPromise(data);

      if (saved && saved.logout == true) {
        this.toastr.error(saved.msg);
        this.clearSubmissionQueue();
        //this.endAnswerSubmission();
        this.router.navigate(["/logout"], {queryParams: { stayinseb: 1 }});
      }
    }
    catch (err) {
      console.log(err);
      alert(this.language.unexpectedCondition);
    }
  }
  // getquestioncount(scheduleid: any):Observable<any> {
  //debugger;
  //   return this.http.get(this.rootURL + `question/getquestioncount?scheduleid=${scheduleid}`);
  // }

  async flagquestion(scheduleid: any, questionno: number, flagged: boolean, candidateno: any) {
    return await this.cbtservice.tryPost(this.rootURL + `question/flagquestionforreview`, { scheduleid, candidateno, questionno, flagged }, { headers: this.req_headers });
  }


  async getallquestionandoptions(useCaptcha: boolean, scheduleid: any): Promise<any> {
    let captchaToken;
    if(useCaptcha){
      await this.loadCaptchaAndWaitForReadiness();
      captchaToken = await grecaptcha.enterprise.execute(this.captchaSiteId, {action: 'getquestions'});
    }
    //setting max attempts to 1 because we don't want to resend captchas
    return await this.cbtservice.tryPost(this.rootURL + `question/getallquestionsandoptions?scheduleId=${scheduleid}${useCaptcha ? `&captchaToken=${captchaToken}` : ''}`, {}, { headers: this.req_headers }, 1);
  }

  saveQuestionsToStorage(data: any): void {
    localStorage.setItem(environment.examQuestionsStore, data);
  }

  async getAllQuestionDataFromStorage(scheduleid: any, litemode: boolean, useCaptcha: boolean){
    let encryptedstoredData = localStorage.getItem(environment.examQuestionsStore);
    if (!encryptedstoredData && !litemode) {
      var questions: any = await this.getallquestionandoptions(useCaptcha, scheduleid);
      this.saveQuestionsToStorage(questions);
      encryptedstoredData = questions;
    }
    const storedData = this.encryptdecryptservice.decryptUsingAES256(encryptedstoredData);
    //debugger;
    let questionlist: any = storedData ? JSON.parse(storedData) : null;
    return questionlist;
  }

  async getQuestionNoFromStorage(questionNo: Number = 1, scheduleid: any, litemode: boolean, useCaptcha: boolean) {
    const questionlist = await this.getAllQuestionDataFromStorage(scheduleid, litemode, useCaptcha);
    if (questionlist) {
      const selectedQuestion = questionlist.questions.find(item => item.examQuestionSchedule.questionno == questionNo);
      let answerdata = this.getAnsweredquestion(selectedQuestion.examQuestionSchedule.schedulequestionid)
      let options = answerdata?.options ?? [];
      selectedQuestion.essayAnswer = answerdata?.essayanswer;
      // Find and update the specified option to selected
      const updatedOptions = selectedQuestion.examQuestionOptions.map(option => {
        if (options.includes(option.optionid.toString())) {
          option.selected = true;
        } else {
          option.selected = answerdata ? false : option.selected;
        }
        return option;
      });

      //debugger;
      //if(litemode){
      selectedQuestion.timeleft = questionlist.timeleft;
      // }
      // else{
      //   selectedQuestion.timeleft = questionlist.timeleft;
      // }
      selectedQuestion.focuslosscount = questionlist.focuslosscount;
      selectedQuestion.examination = questionlist.examination;
      selectedQuestion.exampaused = questionlist.exampaused;
      selectedQuestion.isnotlatestlogin = questionlist.isnotlatestlogin;
      selectedQuestion.examQuestionOptions = updatedOptions;
      console.log('Retrieved specific array:', selectedQuestion);

      return selectedQuestion;
    }
    return null;
  }

  async updateQuestionTimeleft( scheduleid: any, litemode: boolean, useCaptcha: boolean,timeleft:any){
    const questionlist = await this.getAllQuestionDataFromStorage(scheduleid, litemode, useCaptcha);
    if(questionlist){
      questionlist.timeleft = timeleft;
      var encryptedQuestionlist = this.encryptdecryptservice.encryptUsingAES256(JSON.stringify(questionlist))
      this.saveQuestionsToStorage(encryptedQuestionlist);
    }
  }

  clearQuestionStorage(): void {
    localStorage.clear();
  }

  hasQuestionDataInStorage(): boolean {
    const encryptedstoredData = localStorage.getItem(environment.examQuestionsStore);
    // let storedData = this.encryptdecryptservice.decryptEncrpyt(encryptedstoredData,EncryptMode.d);
    // let storedData = this.encryptdecryptservice.decryptUsingAES256(encryptedstoredData);
    // storedData = storedData ? storedData : null;
    // return !!storedData;
    return !!encryptedstoredData;
  }

  saveQuestionOption(data: any) {
    localStorage.setItem(`answerquestion_${data.schedulequestionid}`, JSON.stringify(data));
    // this.updateQuestionOption(data.schedulequestionid, data.options, data.essayanswer)
  }

  // updateQuestionOption(schedulequestionid: any, options: string[], essayAnswer = ""): void {
  //   // Retrieve the current data
  //   const encryptedstoredData = localStorage.getItem(environment.examQuestionsStore);
  //   const storedData = this.encryptdecryptservice.decryptUsingAES256(encryptedstoredData);
  //   const currentList: any = storedData ? JSON.parse(storedData) : [];
  //   let updateOccurred = false;

  //   // Find and update the option of the specified question
  //   let updatedList: any = currentList;
  //   updatedList.questions = currentList.questions.map(item => {
  //     if (item.examQuestionSchedule.schedulequestionid == schedulequestionid && !updateOccurred) {
  //       item.essayAnswer = essayAnswer;

  //       // Find and update the specified option to selected
  //       const updatedOptions = item.examQuestionOptions.map(option => {
  //         if (options.includes(option.optionid.toString())) {
  //           option.selected = true;
  //           updateOccurred = true;
  //         } else {
  //           option.selected = false;
  //         }
  //         return option;
  //       });

  //       // Update only the options and essay answer of the specified question
  //       return { ...item, examQuestionOptions: updatedOptions };
  //     }
  //     return item;
  //   });
  //   // Save the updated list back to local storage
  //   localStorage.setItem(environment.examQuestionsStore, this.encryptdecryptservice.encryptUsingAES256(JSON.stringify(updatedList)));
  // }

  saveTimeForLiteMode() {
    localStorage.setItem(`timeAdded`, new Date().toUTCString());
  }

  getTimeleftForLiteMode(timeleft) {
    const timeAdded = new Date(localStorage.getItem("timeAdded")).getTime() / 1000;
    //debugger;
    const currentTime = new Date(new Date().toUTCString()).getTime() / 1000;
    timeleft = timeleft - (currentTime - timeAdded);
    return timeleft;
  }

  getAllQuestionsAnswer(): any[] {
    const storedData: any[] = [];

    // Iterate over all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key matches the pattern "answerquestion_"
      if (key && key.startsWith('answerquestion_')) {
        // Retrieve the data for the matching key
        const data = localStorage.getItem(key);

        if (data) {
          // Parse the data and add it to the result array
          storedData.push(JSON.parse(data));
        }
      }
    }
    return storedData;
  }

  getAnsweredquestion(schedulequestionid: any) {
    const storedData = localStorage.getItem(`answerquestion_${schedulequestionid}`);
    return JSON.parse(storedData);
  }
}
